import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

export default {
  fillable_by:FillableBy.Both,
  title: "KPS Stockert SCPC System User Competency",
  sections: [
    // {...employeeinfo},
    {
      title: "SCPC TRAINING AND COMPETENCY DEMONSTRATION",
      type: "table",
      heads: constants.tableHeads,
      rows: [
        "Identifies all components of the SCPC System",
        "Shows ability to properly assemble SCPC System",
        "Understands how to turn on SCPC System",
        "Shows ability to navigate touch screen menus",
        "Shows ability to select which monitoring functions are displayed on screen",
        "Correctly enables all safety devices, including level detector, bubble detector, ERC and pressure alarms",
        "Correctly sets high and low limits for flow, RPMs, and pressures",
        "Understands how to assess battery life. Correctly checks to ensure batteries are fully operational",
        "Able to properly perform the battery test",
        "Understands the possible error messages and how to troubleshoot if any were to occur",
        "Properly navigates the setting menu and understands how to make changes to time and date",
        "Shows ability to zero pressure transducer and flow probe",
        "Correctly can switch centrifugal pump from motor to hand crank",
        "Properly wipes down equipment with appropriate sanitizing agents",
        "User knows where to access IFU and is familiar with all its contents",
        "Properly handles equipment ensuring no damage occurs during transportation"
      ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
    },
    {...evaluationCheckList},
    {
      title: "Signatures",
      type: "signature",
      fields: getSignatures(FillableBy.Both)
    },
  ]
}